import React from "react";
import Gallery from 'react-photo-gallery';
import Separator from "react-uwp/Separator";
import Dialog from "react-uwp/Dialog";
import Image from "react-uwp/Image";

export default class App extends React.Component {
    constructor(props){
        super(props)
        this.state={
            showDialog:false,
            img:null
        }
    }
    render(){
        return(
            <div>
                <div style={{fontSize:30, fontWeight:600}}><u>{this.props.title}</u></div>
                <Gallery 
                    photos={this.props.photos} columns={4}
                    onClick={(e,o)=>{
                        this.setState({ showDialog: true });
                        this.setState({ img: o.photo.src })
                    }}
                />
                <Separator/>
                <Dialog
                    defaultShow={this.state.showDialog}
                    style={{ zIndex: 400 }}
                    onCloseDialog={() => this.setState({ showDialog: false })}
                ><Image
                style={{maxWidth:'80vw', maxHeight:'80vh'}}
                src={this.state.img}
              /></Dialog>    
            </div>
        )

    }
}