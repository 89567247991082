import React from "react";
import Dialog from '../reusable/dialog'
import PhotoSet from '../reusable/PhotoSet';
import photoArray from './pictureArray';

export default class App extends React.Component {
    render(){
        return(
            <Dialog title="Gallery">
                <PhotoSet title='ACM Chapter' photos={photoArray.acm}/>
                <PhotoSet title='Code Your Dreams' photos={photoArray.cyd}/>
                <PhotoSet title='PyFlask' photos={photoArray.pyflask}/>
                <PhotoSet title='Defest' photos={photoArray.devfest}/>
                <PhotoSet title='HackOff Event' photos={photoArray.hackoff}/>
            </Dialog>
        )

    }
}
