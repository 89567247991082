import Image from "react-uwp/Image";
import * as React from "react";
import SplitViewCommand from "react-uwp/SplitViewCommand";


// function IconRender(icon){
//     const style: React.CSSProperties = {
//         fontSize: 24,
//         color: this.baseHigh,
//         margin: 10,
//         cursor: "default"
//     };
//     const hoverStyle: React.CSSProperties = {
//         transform: "scale(1.25)",
//         color: this.accent
//     };

//     return (<Icon key="0" {...{ style, hoverStyle }}><i className={icon}></i></Icon>)
// }


export default {
    top: [
        <div style={{padding:'20px 48px', textAlign:'center'}}><Image
        style={{width: '80%',
        height: 'auto',
        display:'absolute',
        maxWidth: 320,
        borderRadius:298,
        maxHeight: 320}}
        src={require("../resources/dp.jpg")}
      /></div>,
      <div/>,
      <div/>,
      <div/>,
      <div style={{padding:'40px 48px', textAlign:'center'}}>Frontend Architect | Backend Developer </div>,
      <div/>,

    //   <SplitViewCommand/>
    //   <div style={{padding:5,textAlign:'center'}}>Fullstack Web Development</div>,
    ],
    // bottom:function(theme){
    //     return[
    //         IconRender.bind(theme)('far fa-envelope'),
    //         IconRender.bind(theme)('fab fa-github'),
    //         IconRender.bind(theme)('fab fa-linkedin-in'),
    //         IconRender.bind(theme)('fab fa-facebook-f'),
    //     ]
    // }
    bottom:[
        // <SplitViewCommand onClick={()=>)} label={
        //     <span>Facebook <span style={{color:'grey'}}>@awasthi.shubh</span></span>
        // }  icon={<i className='fab fa-facebook-f'></i>} />,
        <SplitViewCommand disabled onClick={()=>window.open('mailto:aw.shubh@gmail.com')} label={
            <span>Gmail <span style={{color:'grey'}}>@aw.shubh</span></span>
        } icon={<i className='far fa-envelope'></i>} />,
      <SplitViewCommand onClick={()=>window.open('https://github.com/awasthishubh')} label={
            <span>Github <span style={{color:'grey'}}>@awasthishubh</span></span>
        }  icon={<i className='fab fa-github'></i>} />,
      <SplitViewCommand onClick={()=>window.open('https://www.linkedin.com/in/awasthishubh/')} label={
            <span>linkedIn <span style={{color:'grey'}}>@awasthishubh</span></span>
        }  icon={<i className='fab fa-linkedin-in'></i>} visited={true}/>,
        <SplitViewCommand onClick={()=>window.open('https://www.facebook.com/awasthi.shubh')} label={
            <span>Facebook <span style={{color:'grey'}}>@awasthi.shubh</span></span>
        }  icon={<i className='fab fa-facebook-f'></i>} />,
        
    ]
}