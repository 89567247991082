import React from "react";
import * as PropTypes from "prop-types";
import Separator from "react-uwp/Separator";

export default class App extends React.Component {
    static contextTypes = { theme: PropTypes.object };

    render(){
        var small=window.innerWidth<950
        return <div className="col s12" style={{
            border:'1px solid '+this.context.theme.accent,
            background:this.context.theme.acrylicTexture40.background,
            padding:15,
            overflow:'auto',
            marginBottom:10
            }}>
            <div style={{fontSize:30, fontWeight:600}}><u>{this.props.title}</u></div>
            <p style={{fontSize:15}}>
                {this.props.children}
            </p>
            <Separator/>
            <div style={{display:'flex',justifyContent:'space-around', flexDirection:small?'column':'', alignItems:'center'}}>
                {
                    (()=>{
                        return this.props.buttons.map(function(i){
                            return i
                        })
                    })()
                }
            </div>
            
        </div>
    }
}