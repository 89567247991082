import React from "react";
import Tile from "./Tile"
import { FluentRevealEffect } from "fluent-reveal-effect"

export default class App extends React.Component {
    constructor(props){
        super(props)
        this.state={large:window.innerWidth>820}
    }
    componentDidMount(){
        // FluentRevealEffect.applyEffect(".tiles-container", {
        //     // clickEffect: true,
        //     lightColor: "rgba(255,255,255,0.6)",
        //     gradientSize: 180,
        //     // isContainer: true,
        //     // children: {
        //     //     borderSelector: ".tile",
        //     //     elementSelector: ".tile-in",
        //     //     lightColor: "rgba(255,255,255,0.3)",
        //     //     gradientSize: 150
        //     // }
        // })
        FluentRevealEffect.applyEffect(".tile", {
            lightColor: "rgba(255,255,255,0.1)",
            gradientSize: 150
        })
    }
    render(){
        if(this.state.large) 
            return(
                <div className='tiles-container' style={{display:'flex', flexDirection:'column', height: '55%', width:'100%', maxWidth:580, minWidth:240, padding:'0 5px'}}>
                    <div style={{display:'flex', flex:1, flexDirection:'row'}}>
                        <Tile flex={1} link='about' color="#ff1744" title='About me' icon="fas fa-smile"/>
                        <Tile flex={1} link='skills' color="#3d5afe" title='Skills' icon="fas fa-tachometer-alt"/>
                        <Tile flex={2} link='projects'color="#ec407a" title='Projects' icon="fas fa-project-diagram"/>
                    </div>
                    <div style={{display:'flex', flex:1,flexDirection:'row'}}>
                        <Tile flex={1} link='experience' color="#18ffff" title='Experience' icon="fas fa-flask"/>
                        <Tile flex={2} link='gallery' color="#18ffff" title='Gallery' icon="fas fa-images"/>
                        <Tile flex={1} link='contact' color='#1de9b6' title='Contact' icon="far fa-envelope"/>
                    </div>
                </div>
            )
        else
            return(
                <div className='tiles-container' style={{display:'flex', flexDirection:'column', height:'90%', width:'100%', maxWidth:580, minWidth:240, padding:'0 5px'}}>
                    <div style={{display:'flex', flex:1, flexDirection:'row'}}>
                        <Tile flex={1} link='about' color="#ff1744" title='About me' icon="fas fa-smile"/>
                        <Tile flex={1} link='skills' color="#3d5afe" title='Skills' icon="fas fa-tachometer-alt"/>
                    </div>
                    <div style={{display:'flex', flex:1,flexDirection:'row'}}>
                        <Tile flex={2} link='projects'color="#ec407a" title='Projects' icon="fas fa-project-diagram"/>
                    </div>
                    <div style={{display:'flex', flex:1,flexDirection:'row'}}>
                        <Tile flex={1} link='experience' color="#18ffff" title='Experience' icon="fas fa-flask"/>
                        <Tile flex={1} link='contact' color='#1de9b6' title='Contact' icon="far fa-envelope"/>
                    </div>
                    <div style={{display:'flex', flex:1,flexDirection:'row'}}>
                        <Tile flex={2} link='gallery' color="#18ffff" title='Gallery' icon="fas fa-images"/>
                    </div>
                </div>
            )
        // return (
        //     <div className='tiles-container' style={{display:'flex', flexDirection:'column', height: this.state.large?'60%':'80%', width:'100%', maxWidth:580, minWidth:240, padding:'0 5px'}}>
        //         <div style={{display:'flex', flex:1, flexDirection:'row'}}>
        //             <Tile flex={1} link='about' color="#ff1744" title='About me' icon="fas fa-smile"/>
        //             <Tile flex={1} link='skills' color="#3d5afe" title='Skills' icon="fas fa-tachometer-alt"/>
        //             {
        //                 (()=>{
        //                     if(this.state.large) return <Tile flex={2} link='projects'color="#ec407a" title='Projects' icon="fas fa-project-diagram"/>
        //                     else return <div/>
        //                 })()
        //             }
        //         </div>
        //         <div style={{display:'flex', flex:1,flexDirection:'row'}}>
        //             <Tile flex={1} link='experience' color="#18ffff" title='Experience' icon="fas fa-images"/>
        //             <Tile flex={2} link='gallery' color="#18ffff" title='Gallery' icon="fas fa-images"/>
        //             {(()=>{
        //                     if(this.state.large) return <Tile flex={1} link='contact' color='#1de9b6' title='Contact' icon="far fa-envelope"/>
        //                     else return <div/>
        //                 })()}
        //         </div>
        //         {(()=>{
        //                     if(!this.state.large) return (
        //                         <div style={{display:'flex', flex:1, flexDirection:'row'}}>
        //                             <Tile flex={1} link='projects'color="#ec407a" title='Projects' icon="fas fa-project-diagram"/>
        //                             <Tile flex={1} link='contact' color='#1de9b6' title='Contact' icon="far fa-envelope"/>
        //                         </div>
        //                         )
        //                     else return <div/>
        //                 })()}
                
        //     </div>
        // )

    }
}