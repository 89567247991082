import React from "react";
import Dialog from '../reusable/dialog'
import HyperLink from "react-uwp/HyperLink";

export default class App extends React.Component {
    render(){
        return(
            <Dialog title="Experience">
                <div style={{display:'', flex:1, height:'83vh', padding:30, justifyContent:'center', alignItems:'center'}}>
                <ul className='browser-default' style={{textAlign:'justify'}}>
                    <li style={{fontSize:25, fontWeight:600, textDecoration:'underline'}}>BeChangeMaker 2018</li>
                        BeChangeMaker is launched by BeChangeMakerWorldSkills and the HP Foundation as a virtual 
                        training programme for youth around the world to explore their career potential as social 
                        entrepreneurs and design solutions to solve social issues based on the United Nations 
                        Sustainable Development Goals.
                        I have successfully completed all trainings offered through BeChangeMaker 2018 from June to September 2018. <br/>
                        My team was selected as the top nine teams of BeChangeMaker 2018<br/>
                        <center><HyperLink style={{ margin: "10px 0" }} href="https://bechangemaker.worldskills.org/" target="_blank">
                            https://bechangemaker.worldskills.org/
                        </HyperLink></center>
                        <br/>
                    <li style={{fontSize:25, fontWeight:600, textDecoration:'underline'}}>ACM-VIT</li>
                        ACM-VIT, one of the most reputed and distinguished technical chapter in VIT, 
                        Vellore has been working with vision and determination since it’s inception in 2009,
                        with the aspirations of advancement of computer science as a science and a profession.<br/>
                        I am a part of this chapter for a years. I have worked on several projects and managed various 
                        Technical activities as a core member of this chapter.
                        <center><HyperLink style={{ margin: "10px 0" }} href="https://acmvit.in/" target="_blank">
                            https://acmvit.in/
                        </HyperLink></center>
                </ul>
                </div>
            </Dialog>
        )

    }
}