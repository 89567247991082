import React from "react";
import * as PropTypes from "prop-types";
import { ScaleInOut} from "react-uwp/Animate";
import {Link} from "react-router-dom";

export default class App extends React.Component {
    constructor(props){
        super(props)
        this.state={large:window.innerWidth>550}
    }
    static contextTypes = { theme: PropTypes.object };
    render(){
        console.log(this.state.large?10:0,)
        const { theme } = this.context;
        return (
                <ScaleInOut speed={600} wrapperStyle={{display:'block'}} style={{height:'90vh',display:'block'}}>
                <div id="main" style={{
                border:'1px solid rgb(0, 120, 215)', 
                background:theme.acrylicTexture80.background, 
                display: 'block', height: '100%', 
                flexDirection:'column', 
                margin:this.state.large?10:0,
                overflow:'hidden'
            }}>
                <div style={{display:'flex',flex:1, background:'rgb(0, 120, 215)', height:this.state.large?'38px':'48px'}} >
                    <div style={{display:'flex', flex:1, justifyContent:'center', fontSize:25}} >
                        {this.props.title}
                    </div>
                    <div style={{display:'flex', flex:0}} >
                        <Link to='/' style={{textDecoration:'none',lineHeight:this.state.large?'40px':'45px', fontSize: 30, color: 'white', marginRight:10, cursor:'pointer'}}>
                            <i className="fas fa-times"></i>
                        </Link>
                    </div>
                </div>
                <div className="row" style={{height: 'calc(100% - 48px)',overflow:'auto'}}>
                    <div className="col s12 l10 offset-l1" style={{height:'100%'}}>
                        {this.props.children}
                    </div>
                </div>
            </div>
            </ScaleInOut>
        )

    }
}