export default {
    "cyd": [
        {
            src: require('../../resources/cyd/1.jpg'),
            width: 7,
            height: 4
        },
        {
            src: require('../../resources/cyd/2.jpg'),
            width: 2,
            height: 3
        },
        {
            src: require('../../resources/cyd/3.jpg'),
            width: 4,
            height: 3
        },
        {
            src: require('../../resources/cyd/4.jpg'),
            width: 6,
            height: 3
        },
        {
            src: require('../../resources/cyd/5.jpg'),
            width: 4,
            height: 3
        },
        {
            src: require('../../resources/cyd/6.jpg'),
            width: 2,
            height: 3
        },
        
        {
            src: require('../../resources/cyd/7.jpg'),
            width: 7,
            height: 3
        },
        {
            src: require('../../resources/cyd/8.jpg'),
            width: 6,
            height: 3
        },
        {
            src: require('../../resources/cyd/9.jpg'),
            width: 7,
            height: 3
        },
        {
            src: require('../../resources/cyd/10.jpg'),
            width: 4,
            height: 3
        },
        {
            src: require('../../resources/cyd/11.jpg'),
            width: 4,
            height: 3
        },
        {
            src: require('../../resources/cyd/12.jpg'),
            width: 4,
            height: 3
        }
    ],
    "pyflask": [
        {
            src: require('../../resources/pyflask/1.jpg'),
            width: 5,
            height: 4
        },
        {
            src: require('../../resources/pyflask/2.jpg'),
            width: 6,
            height: 4
        },
        {
            src: require('../../resources/pyflask/3.jpg'),
            width: 5,
            height: 4
        },
        {
            src: require('../../resources/pyflask/4.jpg'),
            width: 1,
            height: 1
        },
    ],
    "devfest": [
        {
            src: require('../../resources/devfest/1.jpg'),
            width: 6,
            height: 4
        },
        {
            src: require('../../resources/devfest/2.jpg'),
            width: 15,
            height: 17
        },
        {
            src: require('../../resources/devfest/3.jpg'),
            width: 4,
            height: 4
        }
    ],
    "hackoff": [
        {
            src: require('../../resources/hackoff/1.jpg'),
            width: 6,
            height: 4
        },
        {
            src: require('../../resources/hackoff/2.jpg'),
            width: 6,
            height: 4
        }
    ]
    ,
    "acm": [
        {
            src: require('../../resources/acm/1.jpg'),
            width: 5,
            height: 4
        },
        {
            src: require('../../resources/acm/2.jpg'),
            width: 6,
            height: 4
        },
        {
            src: require('../../resources/acm/3.jpg'),
            width: 9,
            height: 4
        },
        {
            src: require('../../resources/acm/4.jpg'),
            width: 6,
            height: 4
        }
    ]
}