import * as React from "react";
import * as PropTypes from "prop-types";

import NavigationView from "react-uwp/NavigationView";
import Home from "./Home";
import Content from './content'
import { HashRouter as Router, Route } from "react-router-dom";
import nodes from './navNode'

export default class SimpleExample extends React.Component {
  constructor(props){
    super(props)
    this.state={large:window.innerWidth>550}
  }

  static contextTypes = { theme: PropTypes.object };
  
  render() {
    const { theme } = this.context;
    
    console.log(this.context)
    return (
      
        <div>
          <Router>
            <NavigationView
                isControlled={this.state.large}
                style={{ height: '93vh', margin:`2vh ${this.state.large?2:0}vw`, background:theme.altMedium }}
                pageTitle={<span style={{fontSize:20, fontWeight:600}}>Shubham Awasthi</span>}
                displayMode="compact"
                autoResize={false}
                expandedWidth={window.innerWidth<340?'100%':320}
                navigationTopNodes={nodes.top}
                navigationBottomNodes={nodes.bottom}
                focusNavigationNodeIndex={1}
                paneStyle={{background:theme.acrylicTexture60.background, padding:0}}
                defaultExpanded={this.state.large}
                isTenFt
              >
              
                <Route path="/" exact component={Home} />
                <Route path="/about/" component={Content.About} />
                <Route path="/gallery/" component={Content.Gallery} />
                <Route path="/projects/" component={Content.Projects} />
                <Route path="/skills/" component={Content.Skills} />
                <Route path="/contact/" component={Content.Contact} />
                <Route path="/experience/" component={Content.Experience} />

            </NavigationView>
            </Router>
        </div>
    );
  }
}