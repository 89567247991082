import * as React from "react";
import { Theme as UWPThemeProvider, getTheme } from "react-uwp/Theme";
import Nav from "./components/Nav";
// import ProgressRing from "react-uwp/ProgressRing"

export  default class App extends React.Component {
  constructor(props){
    super(props)
    this.state={loading:true}
    // setTimeout(()=>this.setState({load ng:false}),2000)
  }
  render() {
    // if(!this.state.loading)
    return (
        <UWPThemeProvider style={{background:'rgba(0,0,0,0)'}}
          theme={getTheme({
            themeName: "dark", // set custom theme
            accent: "#0078D7", // set accent color
            useFluentDesign: true, // sure you want use new fluent design.
            desktopBackgroundImage: require('./resources/download.jpg') // set global desktop background image
          })}
        >
        {/* {(()=>{
          if(this.state.loading)
          return (
              <div style={{background:'#000', position:'flex', flex:1,height: '100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <ProgressRing size={130} speed={6125} />
              </div>
          )
          else return<div/>
        })()} */}
          <div style={{padding:10}}>
            <Nav />
          </div>
        </UWPThemeProvider>
    )
  }
}

// ReactDOM.render(
//   <App />,
//   document.getElementById("app")
// );