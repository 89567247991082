import React from "react";
import Dialog from '../reusable/dialog'
import Separator from "react-uwp/Separator";

export default class App extends React.Component {
    render(){
        return(
            <Dialog title="About Me">
                
                <div style={{marginTop:10,lineHeight: 2}}>
                Hi! I am Shubham. I am a 2nd year undergrad in Information Technology at the Vellore Institute of Technology.
                I am a JavaScript full stack Web Developer. I bring designs to code, architect frontend, handle server side and writes backend.
                
                {/* <br/>Apart from my love for web development, I am a devop who can speed up process of developing and delivering with various tools like docker, git, jenkins. */}

                </div><br/>
                <Separator/>
                <br/>
                <div style={{textAlign:'center'}}>
                    <div style={{textAlign:'left', display:'inline-block', fontSize:20, width:'100%'}}>
                            <h5>Personal Information</h5>
                                {/* <EnterRow left='Full Name' right='Shubham Awasthi'/> */}
                                <table className="col s10 offset-s1" style={{fontSize:15}}>
                                    <tbody>
                                    <tr>
                                        <td>Full name</td>
                                        <td>Shubham Awasthi</td>
                                    </tr><tr>
                                        <td>Native City</td>
                                        <td>Lucknow, UP</td>
                                    </tr><tr>
                                        <td>Current City</td>
                                        <td>Vellore, TN</td>
                                    </tr><tr>
                                        <td>Languages</td>
                                        <td>Hindi, English</td>
                                    </tr>
                                    </tbody>
                                </table>
                    </div>
                </div>
            </Dialog>
        )

    }
}