import React from "react";
import Dialog from '../reusable/dialog'
import * as PropTypes from "prop-types";
import Rating from '../reusable/rating';

export default class App extends React.Component {
    static contextTypes = { theme: PropTypes.object };

    render(){
        return(
            <Dialog title="Skills">
                <div>
                    <h4><b>Languages</b></h4>
                    <Rating ttl='JavaScript' lvl={9}/>
                    <Rating ttl='HTML/CSS' lvl={9}/>
                    <Rating ttl='SQL' lvl={8}/>
                    <Rating ttl='Python' lvl={7.5}/>
                    <Rating ttl='Mongo' lvl={7.5}/>
                    <Rating ttl='C/C++' lvl={6}/>
                </div>
                <div>
                    <h4><b>Frameworks/ Libraries</b></h4>
                    <Rating ttl='React' lvl={8}/>
                    <Rating ttl='React-Native' lvl={7.5}/>
                    <Rating ttl='Redux' lvl={6}/>
                    <Rating ttl='Express' lvl={9}/>
                    <Rating ttl='Sails' lvl={8}/>
                    <Rating ttl='Flask' lvl={8}/>
                </div>
                <div>
                    <h4><b>Other Technologies</b></h4>
                    <Rating ttl='Linux' lvl={8}/>
                    <Rating ttl='Docker' lvl={8}/>
                    <Rating ttl='Nginx' lvl={7}/>
                    <Rating ttl='GCP' lvl={6}/>
                </div>
                <div>
                    <h4><b>Personal Skills</b></h4>
                    <Rating ttl='Teamwork' lvl={9}/>
                    <Rating ttl='Adaptability' lvl={8}/>
                    <Rating ttl='Time Management' lvl={8}/>
                    <Rating ttl='Problem-Solving' lvl={7}/>
                    <Rating ttl='Communication Skills' lvl={6}/>
                </div>
                
            </Dialog>
        )

    }
}