import React from 'react'
import {Link} from "react-router-dom";

export default class App extends React.Component {
    render(){
    return (
            <div style={{display:'flex', flex:this.props.flex}}>
                <Link className='tile' to={this.props.link||'/'} style={{
                    display:'flex', 
                    flex:1, 
                    flexDirection:'column', 
                    margin:5,padding:10, 
                    background:'rgba(0, 120, 215,0.5)',
                    color: '#fff',
                    textDecoration: 'none', fontWeight: 'normal'
                }}>
                    <div className='' style={{ flex:1, display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <span style={{fontSize: '300%', color: 'white'}}>
                        <i className={this.props.icon}></i>
                        </span>
                    </div>
                    <div className='' style={{display:'flex', alignItems:'center', justifyContent:'center' }}>
                        {this.props.title}
                    </div>
                </Link>
                </div>
        )

    }
}