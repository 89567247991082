import React from "react";
import * as PropTypes from "prop-types";
import Separator from "react-uwp/Separator";
import RatingControl from "react-uwp/RatingControl";

export default class App extends React.Component {
    static contextTypes = { theme: PropTypes.object };

    render(){
        return(
                <div style={{display:'flex', flex:1, flexDirection:'column',justifyContent:'center'}}>
                        <div style={{display:'flex', flex:1, flexDirection:'row'}}>
                            <div style={{display:'flex', flex:1}}>
                            {this.props.ttl}
                            </div>
                            <div style={{display:'flex', flex:1, justifyContent:'flex-end'}}>
                            {this.props.lvl*10}%
                            </div>
                        </div>
                        <nobr style={{textAlign:'center'}}>
                            <RatingControl
                            iconStyle={{ fontSize: 12 }}
                            iconRatedStyle={{ color: this.context.theme.accent }}
                            isReadOnly
                            icon="RecordLegacy"
                            defaultRating={this.props.lvl}
                            maxRating={10}
                            />
                        </nobr>
                        <Separator disabled />
                </div>
        )

    }
}