import React from "react";
import Dialog from '../reusable/dialog'
import ProjectBox from '../reusable/ProjectBox'
// import GitChart from '../reusable/GitChart'
import Button from "react-uwp/Button";
import Image from "react-uwp/Image";

export default class App extends React.Component {
    render(){
        return(
            <Dialog title="Project">
                {/* <GitChart/> */}
                <div style={{overflow:'auto', textAlign:'center'}}>
                <Image
                    style={{}}
                    src={require("../../resources/github.png")}
                    />
                    </div>
                <div style={{textAlign:'justify'}}>
                    <div className="roa">
                    <ProjectBox title="Freeslot" buttons={[
                        <Button key='1' tooltip="https://freeslot.acmvit.in" style={{margin:1}} onClick={()=>window.open('https://freeslot.acmvit.in')}>
                            Web
                        </Button>,
                        <Button  key='2' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/awasthishubh/freeslots-frontend')}>
                            Source: Frontend
                        </Button>,
                        <Button  key='3' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/awasthishubh/freeslot-backend')}>
                            Source: Backend
                        </Button>
                    ]}>
                        A timetable management system built with React-redux at front and Flask at backend. It uses image processing to extract information from Timetable Screnshot. Organisations can get free members based on member's freeslot.
                    </ProjectBox> 

                    <ProjectBox title="Attendance App" buttons={[
                        <Button  key='1' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/awasthishubh/attendance_sockets')}>
                            Source: Application
                        </Button>,
                        <Button  key='2' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/awasthishubh/attendance-mobile-app')}>
                            Source: Backend
                        </Button>
                    ]}>
                        Android app build with react-native and redux. It's a location based Attendance system that uses location to create lobby of near by devices. It uses Node and socket.io at backend.
                    </ProjectBox>   
                    </div>

                    <div className="roa">
                    <ProjectBox title="WatchPush" buttons={[
                        <Button key='1' tooltip="NPM" style={{margin:1}} onClick={()=>window.open('https://npmjs.com/package/watchpush')}>
                            Package
                        </Button>,
                        <Button  key='2' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/awasthishubh/watchpush')}>
                            Source
                        </Button>
                    ]}>
                        An NPM package that ease the development process on IaaS. With this package you can automatically rebuild and restart your application as you push changes to your github repository.
                    </ProjectBox>
                    <ProjectBox title="Easeoauth" buttons={[
                        <Button key='1' tooltip="NPM" style={{margin:1}} onClick={()=>window.open('https://npmjs.com/package/easeoauth')}>
                            Package
                        </Button>,
                        <Button  key='2' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/awasthishubh/easeoauth')}>
                            Source
                        </Button>
                    ]}>
                        A package to ease the process of oauth authentication using various oauth providers with just a few lines of codes. It returns the very basic information about the authenticated user that oauth provider provides.
                    </ProjectBox> 
                    </div>
                    
                    <div className="roa">
                    <ProjectBox title="Reverse Coding Portal" buttons={[
                        <Button key='1' tooltip="https://rcpc.acmvit.in/" style={{margin:1}} onClick={()=>window.open('https://rcpc.acmvit.in/')}>
                            WEB
                        </Button>,
                        <Button  key='2' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/awasthishubh/ReverseCoding')}>
                            Source: Frotend
                        </Button>,
                        <Button  key='3' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/awasthishubh/Reverse_Coding_2018')}>
                            Source: Backend
                        </Button>
                    ]}>
                        A portal to let participant of RC to submit their codes. Codes run and gets evaluated against know test cases and results automatically at backend with the help of box-exec module and docker. It supported only C/C++ and Python language.
                    </ProjectBox>
                    </div>

                    <div className="roa">
                    <ProjectBox title="ACM Reach Out To Us" buttons={[
                        <Button key='1' tooltip="" style={{margin:1}} onClick={()=>window.open('http://ShivankSahai.github.io/ACM_Reach_Out_To_Us-Frontend-')}>
                            WEB
                        </Button>,
                        <Button  key='2' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/ShivankSahai/ACM_Reach_Out_To_Us-Frontend-')}>
                            Source: Frotend
                        </Button>,
                        <Button  key='3' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/awasthishubh/ACM_Reach_Out_To_Us')}>
                            Source: Backend
                        </Button>
                    ]}>
                        A frontend and basic backend for storing responses for ACM Reachout to us portal.
                    </ProjectBox> 
                    <ProjectBox title="WebCanvass" buttons={[
                        <Button key='1' tooltip="" style={{margin:1}} onClick={()=>window.open('http://ShivankSahai.github.io/ACM_Reach_Out_To_Us-Frontend-')}>
                            WEB
                        </Button>,
                        <Button  key='2' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/ShivankSahai/ACM_Reach_Out_To_Us-Frontend-')}>
                            Source: Frotend
                        </Button>,
                        <Button  key='3' tooltip="Github" style={{margin:1}} onClick={()=>window.open('https://github.com/awasthishubh/ACM_Reach_Out_To_Us')}>
                            Source: Backend
                        </Button>
                    ]}>
                        My first Web project build with basic html, css, js and materialize framework Codeigniter at front and PHP framework at backend. <br/>
                        It's a Web Application where people can post issues and their topics of interest and others can give their replies, views and have a discussion on it.
                    </ProjectBox>   
                    </div>
                </div>
            </Dialog>

        )

    }
}