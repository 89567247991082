import Button from "react-uwp/Button";
import React from "react";
import Dialog from '../reusable/dialog'
import TextBox from "react-uwp/TextBox";
import $ from "jquery";
import Separator from "react-uwp/Separator";
import IconButton from "react-uwp/IconButton";

export default class App extends React.Component {
    constructor(props){
        super(props)
        this.state={msg:''}
    }
    componentDidMount(){
        $("input#message").each(function () {
            console.log(this.placeholder)
            console.log(this)
            var $txtarea = $("<textarea />");
            $txtarea.attr("required", this.required);
            $txtarea.attr("class", this.className);
            $txtarea.attr("placeholder", this.placeholder);
            $txtarea.attr("id", this.id);
            $txtarea.attr("rows", 8);
            $txtarea.attr("cols", 60);        
            $txtarea.val(this.value);
            $(this).replaceWith($txtarea);
        });
    }
    send(e){
        e.preventDefault()
        this.setState({msg:'Sending...'})
        window.emailjs.send("gmail", "template_2R074DMp", {
            "name":document.getElementById('name').value,
            "email":document.getElementById('email').value,
            "message":document.getElementById('message').value,
            "ejs_dashboard__test_template":true},'user_9mxHU5FskG67KLw6nkdQL').then(()=>{
                console.log('sent')
                this.setState({msg:'Message sent!'})
                console.log(this.state.msg)
            }).catch((e)=>{
                console.log(e)
                alert('Unable to send message')
            })
            return false
    }
    render(){
        return(
        <Dialog title="Contact me">
            <div style={{flex:1, padding:10, height:'82vh',display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                <div style={{display:'flex',flex:1,justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                {/* Contact me */}
                <form action="#" onSubmit={this.send.bind(this)}>
                    <div style={{margin:10}}>
                        <div style={{display:'inline-block', width:'30%'}}>Name</div>
                        <div style={{display:'inline-block', width:'70%'}}>
                            <TextBox
                                className="browser-default"
                                required
                                type="name"
                                id='name'
                                style={{width:'100%'}}
                                placeholder="Your name"
                            />
                        </div>
                    </div>

                    <div style={{margin:10}}>
                        <div style={{display:'inline-block', width:'30%'}}>Email</div>
                        <div style={{display:'inline-block', width:'70%'}}>
                            <TextBox
                                className="browser-default"
                                required
                                id='email'
                                type="email"
                                style={{width:'100%'}}
                                placeholder="Your Email address"
                            />
                        </div>
                    </div>

                    <div style={{margin:10}}>
                        <div style={{display:'inline-block', width:'30%', verticalAlign: 'top'}}>Message</div>
                        <div style={{display:'inline-block', width:'70%'}}>
                            <TextBox
                            className="browser-default"
                                required
                                id="message"
                                style={{width:'100%', height:200}}
                                placeholder="Enter your message here"
                            />
                        </div>
                    </div>
                    <Button
                        style={{width:'100%'}}
                        type="submit"
                        icon="Send"
                        iconPosition="right"
                    >
                        Send
                    </Button>
                    <center>
                    {this.state.msg}
                    </center>
                </form>
                </div>
                <br/>
                
                <Separator disabled />

                <div style={{display:'flex',flexDirection:'comumn'}}>
                    <IconButton onClick={()=>window.open('mailto:aw.shubh@gmail.com')}>
                        <i className='far fa-envelope'></i>
                    </IconButton>
                    <IconButton onClick={()=>window.open('https://github.com/awasthishubh')}>
                        <i className='fab fa-github'></i>
                    </IconButton>
                    <IconButton onClick={()=>window.open('https://www.linkedin.com/in/awasthishubh/')}>
                        <i className='fab fa-linkedin-in'></i>
                    </IconButton>
                    <IconButton onClick={()=>window.open('https://www.facebook.com/awasthi.shubh')}>
                        <i className='fab fa-facebook-f'></i>
                    </IconButton>
                </div>
            </div>
        </Dialog>
        )

    }
}