import React from "react";
import Tiles from "./Tiles"
import { FadeInOut} from "react-uwp/Animate";
export default class App extends React.Component {
    render(){
        return (
            <FadeInOut className="dfbdfd" speed={1000} wrapperStyle={{height:'100%',flex: 1,display: 'flex'}} style={{flex: 1,display: 'flex'}}>
                <div id="main" style={{flex: 1,display: 'flex', height: '100%', flexDirection:'column',    overflow: 'auto'}}>
                    <div style={{textAlign:'center', fontSize:50, fontWeight:900, marginTop:window.innerWeidth>820?'10vh':'3vh'}}>
                        Hi there!
                    </div>
                    <center>This is Shubham. Welcome to my portfolio</center>
                    <div style={{display:'flex', flex:1,flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <Tiles/>
                    </div>
                </div>
            </FadeInOut>
        )

    }
}